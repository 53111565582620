import 'core-js/features/array/at'
import { ChakraProvider } from '@chakra-ui/react'
import {
  defaultContext,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { AIComponentsProvider } from 'ai-components'
import { appPropsAtom, AppPropsCommon } from 'atoms/app-props.atom'
import ErrorBoundary from 'components/ErrorHandler'
import { Provider, useSetAtom } from 'jotai'
import type { AppProps } from 'next/app'
import '../styles/global.css'
import Head from 'next/head'
import NextNprogress from 'nextjs-progressbar'
import React, { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import 'react-toastify/dist/ReactToastify.css'
import { useCompany } from 'stores/company.store'
import { customTheme, primaryColor } from 'ui'
import Script from 'next/script'
import { useRouter } from 'next/router'
import { FRONTEND_ENV } from 'config/config.exporter'
import { LanguageProvider } from 'i11n/client'

const JotaiContainer = (
  props: React.PropsWithChildren<{
    pageProps: AppPropsCommon
  }>,
) => {
  const setAppProps = useSetAtom(appPropsAtom)

  useEffect(() => {
    if (props.pageProps) {
      setAppProps(props.pageProps)
    }
  }, [setAppProps, props.pageProps])

  return <>{props.children}</>
}

//dumb comment to trigger deploy
function MyApp({
  Component,
  pageProps,
}: Pick<AppProps, 'Component'> & {
  pageProps: AppPropsCommon
}) {
  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then((registrations) => {
          for (let registration of registrations) {
            registration
              .unregister()
              .then((res) => console.log('UNREGISTER: ', res))
          }
        })
        .catch((error) => {
          console.log('GET REGISTRATIONS ERROR: ', error)
        })
    }
  }, [])

  const setCompanyConfig = useCompany((state) => state.setConfig)

  useEffect(() => {
    if (pageProps.user?.currentMembership?.config) {
      setCompanyConfig(pageProps.user.currentMembership.config)
    }
  }, [pageProps.user])

  const [queryClient] = React.useState(() => new QueryClient())

  const router = useRouter()

  useEffect(() => {
    if (FRONTEND_ENV === 'development') {
      return
    }

    const handleRouteChange = (url: string) => {
      window.gtag('config', process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID, {
        page_path: url,
      })
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <LanguageProvider lang='es'>
      <QueryClientProvider context={defaultContext} client={queryClient}>
        <AIComponentsProvider queryClientCtx={defaultContext}>
          <DndProvider key={1} backend={HTML5Backend}>
            <ChakraProvider theme={customTheme}>
              <ErrorBoundary>
                <NextNprogress
                  showOnShallow={false}
                  options={{
                    showSpinner: false,
                  }}
                  color={primaryColor}
                />
                <Head>
                  <meta charSet='utf-8' />
                  <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
                  <script src='https://sdk.mercadopago.com/js/v2' />
                  <meta
                    name='viewport'
                    content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'
                  />
                  <meta name='description' content='Autoinspector' />

                  <link rel='icon' href='/assets/icon-192x192.png?v=2' />

                  {pageProps.user && FRONTEND_ENV !== 'development' && (
                    <>
                      <script
                        dangerouslySetInnerHTML={{
                          __html: `
                   window.intercomSettings = {
                    api_base: "https://api-iam.intercom.io",
                    app_id: "sr66n0tz",
                    horizontal_padding: 20,
                    vertical_padding: 70,
                    name: "${pageProps.user.firstName} ${
                            pageProps.user.lastName
                          }",
                    email: "${pageProps.user.email}",
                    created_at: "${
                      new Date(pageProps.user.createdAt).getTime() / 1000
                    }"
                  };
               `,
                        }}
                      />

                      <script
                        dangerouslySetInnerHTML={{
                          __html: `
                      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/sr66n0tz'
                      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/sr66n0tz';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
              `,
                        }}
                      />
                    </>
                  )}

                  {FRONTEND_ENV !== 'development' && (
                    <script
                      dangerouslySetInnerHTML={{
                        __html: `
                  (function(h,o,t,j,a,r){
                    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                    h._hjSettings={hjid:3296962,hjsv:6};
                    a=o.getElementsByTagName('head')[0];
                    r=o.createElement('script');r.async=1;
                    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                    a.appendChild(r);
                  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
              `,
                      }}
                    />
                  )}
                </Head>
                <style jsx global>
                  {`
                    .space-x-0
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0px * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0px * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }

                    .space-x-1
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0.25rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0.25rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-2
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-3
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0.75rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0.75rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-4
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        1rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        1rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        1.25rem * var(--tw-space-x-reverse)
                      ) !important ;
                      margin-left: calc(
                        1.25rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-6
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        1.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        1.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-7
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        1.75rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        1.75rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-8
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        2rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        2rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-9
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        2.25rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        2.25rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-10
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        2.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        2.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-11
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        2.75rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        2.75rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-12
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        3rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        3rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-14
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        3.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        3.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-16
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        4rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        4rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-20
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-24
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        6rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        6rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-28
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        7rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        7rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-32
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        8rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        8rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-36
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        9rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        9rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-40
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        10rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        10rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-44
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        11rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        11rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-48
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        12rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        12rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-52
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        13rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        13rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-56
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        14rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        14rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-60
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        15rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        15rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-64
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        16rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        16rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-72
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        18rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        18rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-80
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        20rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        20rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-96
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        24rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        24rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-px
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        1px * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        1px * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-0\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0.125rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0.125rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-1\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0.375rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0.375rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-2\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0.625rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0.625rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-x-3\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0.875rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0.875rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-0
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        0px * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        0px * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-1
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -0.25rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -0.25rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-2
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -0.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -0.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-3
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -0.75rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -0.75rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-4
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -1rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -1rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -1.25rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -1.25rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-6
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -1.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -1.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-7
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -1.75rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -1.75rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-8
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -2rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -2rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-9
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -2.25rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -2.25rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-10
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -2.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -2.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-11
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -2.75rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -2.75rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-12
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -3rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -3rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-14
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -3.5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -3.5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-16
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -4rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -4rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-20
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -5rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -5rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-24
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -6rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -6rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-28
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -7rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -7rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-32
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -8rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -8rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-36
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -9rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -9rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-40
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -10rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -10rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-44
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -11rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -11rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-48
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -12rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -12rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-52
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -13rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -13rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-56
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -14rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -14rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-60
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -15rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -15rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-64
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -16rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -16rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-72
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -18rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -18rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-80
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -20rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -20rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-96
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -24rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -24rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-px
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -1px * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -1px * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-0\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -0.125rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -0.125rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-1\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -0.375rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -0.375rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-2\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -0.625rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -0.625rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .-space-x-3\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-x-reverse: 0 !important;
                      margin-right: calc(
                        -0.875rem * var(--tw-space-x-reverse)
                      ) !important;
                      margin-left: calc(
                        -0.875rem * calc(1 - var(--tw-space-x-reverse))
                      ) !important;
                    }
                    .space-y-0
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0px * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0px * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-1
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0.25rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0.25rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-2
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-3
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0.75rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0.75rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-4
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important ;
                      margin-top: calc(
                        1rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        1rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        1.25rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        1.25rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-6
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        1.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        1.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-7
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        1.75rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        1.75rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-8
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        2rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        2rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-9
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        2.25rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        2.25rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-10
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        2.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        2.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-11
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        2.75rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        2.75rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-12
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        3rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        3rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-14
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        3.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        3.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-16
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        4rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        4rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-20
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-24
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        6rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        6rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-28
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        7rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        7rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-32
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        8rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        8rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-36
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        9rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        9rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-40
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        10rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        10rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-44
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        11rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        11rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-48
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        12rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        12rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-52
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        13rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        13rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-56
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        14rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        14rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-60
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        15rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        15rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-64
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        16rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        16rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-72
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        18rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        18rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-80
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        20rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        20rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-96
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        24rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        24rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-px
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        1px * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        1px * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-0\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0.125rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0.125rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-1\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0.375rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0.375rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-2\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0.625rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0.625rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .space-y-3\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0.875rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0.875rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-0
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        0px * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        0px * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-1
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -0.25rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -0.25rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-2
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -0.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -0.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-3
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -0.75rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -0.75rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-4
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -1rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -1rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -1.25rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -1.25rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-6
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -1.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -1.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-7
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -1.75rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -1.75rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-8
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -2rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -2rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-9
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -2.25rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -2.25rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-10
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -2.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -2.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-11
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -2.75rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -2.75rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-12
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -3rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -3rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-14
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -3.5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -3.5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-16
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -4rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -4rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-20
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -5rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -5rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-24
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -6rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -6rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-28
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -7rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -7rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-32
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -8rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -8rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-36
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -9rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -9rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-40
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -10rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -10rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-44
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -11rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -11rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-48
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -12rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -12rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-52
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -13rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -13rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-56
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -14rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -14rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-60
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -15rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -15rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-64
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -16rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -16rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-72
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -18rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -18rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-80
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -20rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -20rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-96
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -24rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -24rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-px
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -1px * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -1px * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-0\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -0.125rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -0.125rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-1\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -0.375rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -0.375rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-2\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -0.625rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -0.625rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                    .-space-y-3\.5
                      > :not([hidden]):not(style)
                      ~ :not([hidden]):not(style) {
                      --tw-space-y-reverse: 0 !important;
                      margin-top: calc(
                        -0.875rem * calc(1 - var(--tw-space-y-reverse))
                      ) !important;
                      margin-bottom: calc(
                        -0.875rem * var(--tw-space-y-reverse)
                      ) !important;
                    }
                  `}
                </style>
                {FRONTEND_ENV !== 'development' && (
                  <>
                    <Script
                      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
                    />
                    <Script id='google-analytics'>
                      {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
          
                    gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}', {
                      page_path: window.location.pathname
                    });
                  `}
                    </Script>
                  </>
                )}

                <Provider initialValues={[[appPropsAtom, pageProps]]}>
                  <JotaiContainer pageProps={pageProps}>
                    <Component isMobile={false} {...pageProps} />
                  </JotaiContainer>
                </Provider>
              </ErrorBoundary>
            </ChakraProvider>
          </DndProvider>
        </AIComponentsProvider>
      </QueryClientProvider>
    </LanguageProvider>
  )
}

export default MyApp
